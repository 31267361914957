.App {
  text-align: center;
  background-image: linear-gradient(110deg, #3d3393 0%, #2b76b9 25%, #2cacd1 50%, #35eb93 100%);
  min-height: calc(100vh - 100px);
  margin-top: 100px;
}

nav{
  position: fixed;
  display: flex;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background: rgb(73, 79, 82);
  font-family: "Burbank Big Condensed";
  text-transform: uppercase;
  font-size: 24px;
  box-shadow: 0px 2px 10px rgb(71, 71, 71);
  z-index: 99;
}

.nav-links{
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.link-toggle {
  display: none;
}

@media only screen and (max-width: 768px) {
  .nav-links{
    flex-direction: column;
  }
  .nav-links {
    display: none;
  }
  .link-toggle {
    align-self: flex-end;
    display: initial;
    position: absolute;
    cursor: pointer;
    top: 32%;
    left: 80%;
  }
  .toggleShow {
    position: absolute;
    width: 100%;
    display: flex;
    top: 75px;
    background: rgb(73, 79, 82);
    padding-bottom: 20px;
    padding-left: 0;
  }
}

.navStyle{
  color: #aaaeb9 !important;
  text-decoration: none;
  margin: 5px;
}

.navStyle:hover{
  color: #bec2ce !important;
}

.navStyleLogo{
  color: #aaaeb9 !important;
  text-decoration: none;
}

.navStyleLogo:hover{
  color: #bec2ce !important;
}

.selectBarNav{
  width: 120px;
  border: none;
  height: 30px;
  font-family: "Burbank Big Condensed";
  font-size: 24px;
  color: #5f6168;
  border-radius: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  top: -10px;
  left: 107%;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 37%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #333 transparent transparent;
}

/* Home */
.startPic{
  min-height: calc(85vh - 125px);
  background-image: url('images/fn-lp.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
}

.shadowBox{
  position: relative;
  box-shadow: 0px -5px 5px #647f80cc;
  width: 100%;
  height: 20px;
}

.homeStart{
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 11%;
}

.homeStartInfo{
  margin: 20px;
}

.fnMap{
  width: 300px;
  height: 300px;
  margin: 20px;
  border-radius: 10px;
}

.fnMap:hover{
  transform: scale(1.1);
  transition: 500ms;
}

.homeNews{
  display: block;
  justify-content: center;
  background: #3E3E42;
  width: 75%;
  max-width: 1050px;
  min-height: 50px;
  padding-bottom: 20px;
  margin-top: 30px;
  margin-bottom: 50px;
  border-radius: 10px;
}

.newsHeader{
  color: white;
  margin: 20px !important;
}


.newsItems{
  display: flex;
  border-color: grey;
  border-style: solid;
  border-radius: 10px;
  margin: 5px;
}

.imageNews{
  height: 200px;
  width: auto;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}

.newsTextBox{
  width: 100%;
}
.newsTitle{
  width: 100%;
  font-family: "Burbank Big Condensed";
  font-size: 24px;
  color: whitesmoke;
}

.newsBody{
  color: #2cacd1;
  margin: 20px 5px;
}

.newsInfo{
  display: flex;
  justify-content: space-between;
}

.newsLive{
  margin-left: 10px;
}

.newsLiveYes{
  color: #35eb93;
}

.newsLiveNo{
  color: #b36627;
}

.newsDate{
  color: #7D7D7D;
  margin-right: 10px;
}


@media only screen and (max-width: 990px) {
  .imageNews{
    height: 150px;
  }
}

@media only screen and (max-width: 790px) {
  .imageNews{
    height: 120px;
  }
  .newsTitle{
    font-size: 16px;
  }
  .newsBody{
    font-size: 14px;
  }
  .newsLive{
    font-size: 14px;
  }
  .newsDate{
    font-size: 14px;
  }
}

@media only screen and (max-width: 570px) {
  .imageNews{
    width: 100%;
    height: auto;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 7px;
  }
  .newsItems{
    display: block;
  }
}


/* Statistics */
.searchField{
  background: #3E3E42;
  margin-bottom: 50px;
  height: 200px;
}

.searchHeader{
  padding-top: 40px;
  color: #7D7D7D;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Burbank Big Condensed";
  margin-block-end: 0 !important;
  margin-block-start: 0 !important;
}

.searchForm{
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.searchBar{
  width: 400px;
  border: none;
  height: 33px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: "Burbank Big Condensed";
  font-size: 22px;
  margin: 10px;
}

.selectBar{
  width: 150px;
  border: none;
  margin: 0 10px 0 0;
  height: 35px;
  font-family: "Burbank Big Condensed";
  font-size: 22px;
}


.searchButton,
.platformButtons{
  background: rgb(61, 161, 192);
  border: none;
  padding: 0px 20px;
  height: 35px;
  color: #333;
  font-family: "Burbank Big Condensed";
  font-size: 22px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

}

.searchButton:hover,
.platformButtons:hover{
  background: rgb(35, 188, 248);
  cursor: pointer;
}

.selectForm{
  width: 150px;
}


.statisticsSite{
  display: block;
}


/* UserTag */
.userBanner{
  max-width: 800px;
  height: auto;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  background: #3E3E42;
  border-radius: 10px;
}

.divider{
  border: #7D7D7D;
  border-bottom-style: solid;
  border-width: 2px;
}

.userTextes,
.statsText,
.bannerHeader{
  height: 40px;
  line-height: 40px;
  font-family: "Burbank Big Condensed";
  font-size: 22px;
  color: #7D7D7D;
}

.userTagInfo{
  height: 150px;
  display: flex;
  height: auto;
  justify-content: space-between;
  margin: 10px 30px;
  flex-wrap: wrap;
}

.gamerName,
.statsInfo{
  display: flex;
}

.userTextes,
.statsText{
  font-size: 28px;
  border: none;
  margin-right: 5px;
}

.userName{
  color: #E48822;
}

.userOptions{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.platformButtons{
  height: 30px;
  border-radius: 0 !important;
  margin-top: 5px;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.platformActive{
  background: #67B81D;
}

.userGameInfo{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  min-height: 50px;
  margin-top: 10px;
}

.userLocked{
  display: block;
}

.fasSymbol{
  font-size: 150px;
  color: #E48822;
  height: auto;
}

.notValid{
  color: #a03035;
}

.loading{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
  min-height: 67px;
  padding-top: 10px;
  padding-bottom: 15px;
}

.loadingTextes{
  width: 200px;
}

.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 0px 8px;
  border-radius: 50%;
  border: 6px solid #E48822;
  border-color: #E48822 transparent #E48822 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.userStatsWrapper{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 415px) {
 .userBanner{
  margin-left: 15px;
  margin-right: 15px;
 }
}

/* UserStats */
.statsWrapper{
  display: block;
  width: 300px;
  margin: 25px;
}

.statsHeader{
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 0 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.statsGame{
  columns: #333;
  font-family: "Burbank Big Condensed";
  font-size: 32px;
}

.gameCount{
  color: white;
  font-family: "Burbank Big Condensed";
  font-size: 18px;
  line-height: 40px;
}

.statsList,
.statsListEmpty{
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  background: #3E3E42;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.statsListEmpty{
  display: block;
  height: 352px;
  justify-content: center;
}

.emptyList{
  position: relative;
  top: 30%;
  color: #7D7D7D;
  font-size: 44px;
}

.emptyListText{
  margin-top: 10px;
  font-size: 24px;
  text-transform: uppercase;
  font-family: Helvetica;
  font-weight: 600;
}

.statsBox{
  text-align: left;
  margin: 20px 0px;
  width: 100px;
  border-style: solid;
  border-color: springgreen;
  border-left: none;
  border-top: none;
  border-right: none;
}

.statsKey{
  color: #7D7D7D;
  font-family: Helvetica;
  font-size: 14px;
  min-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
}

.statsValue{
  color: white;
  font-family: Helvetica;
  font-size: 24px;
}


/* RecentGames */
.tableImage{
  width: 75px;
  height: auto;
}

table{
  background: #3E3E42;
  border-radius: 10px;
  border-spacing: 0;
  margin: 25px 15px 50px 15px;
  max-width: 800px;
}

thead{
  background: #ea8d23;
}

.tableHead{
  height: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: "Burbank Big Condensed";
  font-size: 32px;
  color: #333;
}

th{
  color: white;
  font-family: "Burbank Big Condensed";
  font-size: 18px;
  max-width: 100px;
  padding: 0 20px;
}

td{
  max-width: 100px;
  border-width: 2px 20px;
  border-style: solid;
  border-color: #3E3E42;
  padding: 5px 5px 1px 5px;
  color: #A7A7A7;
  border-bottom-color: springgreen;
  border-left: none;
  border-top: none;
  border-right: none;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
}

.modeText{
  text-align: left !important;
}

.tableBottom{
  height: 14px;
  border-bottom: none;
}

@media only screen and (max-width: 450px) {
  th{
    font-size: 10px;
    padding: 0 3px;
  }

  td{
    font-size: 10px;
    padding: 3px 3px 1px 3px;
  }

  .tableImage{
    width: 35px;
  }
  
}

/* Shop */
.shopCard{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card{
  width: 150px;
  min-height: 250px;
  padding: 20px;
  margin: 40px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgb(71, 71, 71);
}

.card:hover{
  transform: scale(1.1);
  transition-duration: 500ms;
}

.cardName{
  text-decoration: underline;
}

.images{
  width: 120px;
}

.linkText{
  text-decoration: none !important;
  color: white;
  font-family: "Burbank Big Condensed";
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 0 0 10px rgba(28, 91, 115, .5);
  font-weight: 500;
}

.itemSite{
  min-height: 500px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  display: flex;
  justify-content: center;
}

.itemBox{
  display: block;
  margin-top: 75px;
  width: 500px;
  height: auto;
  padding: 20px;
  border-radius: 10px;
}

.itemContent{
  display: flex;
  justify-content: space-between;
}

.itemText{
  display: block;
  margin-right: 20px;
  text-align: left;
}

.itemName,
.itemDescr{
  font-size: 32px;
  color: white;
  font-family: "Burbank Big Condensed";
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(28, 91, 115, .5);
}

.itemDescr{
  font-size: 22px;
}

.imagesItem{
  width: 250px;
  right: 0;
}

.imagesItem:hover{
  transform: scale(1.15);
  transition-duration: 1000ms;
}

@media only screen and (max-width: 415px) {
  .itemBox{
    margin-top: 75px;
    width: 300px;
    height: 350px;
    padding: 15px;
  }

  .itemName,
  .itemDescr{
    font-size: 24px;
  }
  
  .itemDescr{
    font-size: 16px;
  }

  .imagesItem{
    width: 175px;
  }
}

@media only screen and (max-width: 320px) {
  .itemBox{
    margin-top: 50px;
    width: 275px;
    height: 300px;
    padding: 10px;
  }

  .itemName,
  .itemDescr{
    font-size: 20px;
  }
  
  .itemDescr{
    font-size: 14px;
  }

  .imagesItem{
    width: 150px;
  }
}

.backButton{
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  height: 35px;
  background: #6c92ac;
  line-height: 1.2;
  color: white;
  font-family: "Burbank Big Condensed";
  font-size: 32px;
  border-radius: 10px;
}

.backButton:hover{
  cursor: pointer;
  background: #4a6475;
}

.countDown,
.siteInfoBar{
  background: darkgrey;
  width: 100%;
  height: 200px;
  padding-top: 0.67em;
}

.siteInfoBar{
  height: 40px;
}

h1,h2, h3 {
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Burbank Big Condensed";
  margin-block-start: 0 !important;
}

.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: #333;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 10px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
  font-family: "Burbank Big Condensed";
}

.countdown-item span {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}


@media only screen and (max-width: 360px) {
  .countDown{
    height: 250px;
  }
}

@media only screen and (max-width: 320px) {
  .countdown-item {
    font-size: 32px;
    margin: 5px;
    padding-top: 5px;
    width: 80px;
  }
}

.shopButtons{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 10px 0;
  background: rgb(62, 62, 66);
  text-transform: uppercase;
}

.shopButton{
  width: 45%;
  line-height: 1.5;
  color: white;
  font-family: "Burbank Big Condensed";
  font-size: 32px;
  border-radius: 10px;
}

.shopButton:hover{
  cursor: pointer;
  background: #4a6475;
}

.shopButtonActive{
  background: #4585af;
}

.shopButtonActive:hover{
  background: #4585af;
}

@media only screen and (max-width: 720px) {
  .shopButton{
    line-height: 2.5;
    font-size: 20px;
  }
}

@media only screen and (max-width: 420px) {
  .shopButton{
    line-height: 3;
    font-size: 16px;
  }
}

@media only screen and (max-width: 320px) {
  .shopButton{
    line-height: 3.5;
    font-size: 14px;
  }
}

/* Rarity Types */
.cardHandmade{
  background-image: radial-gradient(#F1F1F1, #BCBCBC);
  border-color: #F1F1F1;
  border-style: solid;
  border-width: 2px;
}

.cardCommon{
  background-image: radial-gradient(#bebebe, #646464);
  border-color: #b1b1b1;
  border-style: solid;
  border-width: 2px;
}

.cardUncommon{
  background-image: radial-gradient(#69bb1e, #175117);
  border-color: #87e339;
  border-style: solid;
  border-width: 2px;
}

.cardRare{
  background-image: radial-gradient(#2cc1ff, #143977);
  border-color: #37d1ff;
  border-style: solid;
  border-width: 2px;
}

.cardEpic{
  background-image: radial-gradient(#c359ff, #4b2483);
  border-color: #e95eff;
  border-style: solid;
  border-width: 2px;
}

.cardLegendary{
  background-image: radial-gradient(#ea8d23, #78371d);
  border-color: #e98d4b;
  border-style: solid;
  border-width: 2px;
}

.cardMythic{
  background-image: radial-gradient(#cfa939, #a77d0b);
  border-color: #947942;
  border-style: solid;
  border-width: 2px;
}

.cardThranscendent{
  background-image: radial-gradient(#931414, #7D0B0B);
  border-color: #931414;
  border-style: solid;
  border-width: 2px;
}

.cardMarvel{
  background-image: radial-gradient(#c53334, #761b1b);
  border-color: #ef3537;
  border-style: solid;
  border-width: 2px;
}

.cardDark{
  background-image: radial-gradient(#fb22df, #520c6f);
  border-color: #ff42e7;
  border-style: solid;
  border-width: 2px;
}

.cardDc{
  background-image: radial-gradient(#5475c7, #243461);
  border-color: #6094ce;
  border-style: solid;
  border-width: 2px;
}

.cardFrozen{
  background-image: radial-gradient(#72bfe2, #176f97);
  border-color: #aad0ee;
  border-style: solid;
  border-width: 2px;
}

.cardLava{
  background-image: radial-gradient(#7e0e39, #560438);
  border-color: #a03035;
  border-style: solid;
  border-width: 2px;
}

.cardIcon{
  background-image: radial-gradient(#096574, #002e3e);
  border-color: #40b8c7;
  border-style: solid;
  border-width: 2px;
}

.cardShadow{
  background-image: radial-gradient(#77757a, #29292d);
  border-color: #6e6e6e;
  border-style: solid;
  border-width: 2px;
}

.cardStarwars{
  background: radial-gradient(#090909, #090909);
  background-image: url('https://media.fortniteapi.io/images/swseries512.png');
  border-color: #4c4c4c;
  border-style: solid;
  border-width: 2px;
}

.cardSlurp{
  background-image: radial-gradient(#0bb8a8, #03f1ed);
  border-color: #008bde;
  border-style: solid;
  border-width: 2px;
}